<template>
  <v-dialog v-model="dialog" width="700" retain-focus @click:outside="cancel" @keydown.esc="cancel">
    <v-toolbar flat dark color="primary">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="build">{{ 'APP - ' + build.app_build }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card flat class="px-0 py-2">
      <v-card-text class="px-2">
        <v-list>
          <v-row v-if="build" no-gutters>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Branch:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.branch }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Fastlane:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.fast_lane_action }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Workflow:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.workflow_id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Usuário:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.user ? build.user.name : '-' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Changelog:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.changelog || '-' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Teste:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.test ? 'Sim' : 'Não' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row v-if="build && build.build_output && build.details" no-gutters>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Build Slug:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.build_output.build_slug }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Nº da build:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.build_output.build_number }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="build.details.abort_reason">
                <v-list-item-content>
                  <v-list-item-title>Motivo da interrupção da build:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.details.abort_reason || '-' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Status:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.details.status_text ? build.details.status_text.toUpperCase() : 'Não identificado' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Mensagem:</v-list-item-title>
                  <v-list-item-subtitle>{{ build.build_output.message }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="build && build.details" class="pt-2 px-4">
        <v-btn :disabled="!build.build_status" color="info" @click="openStatus"><v-icon class="mr-1">info</v-icon> Status</v-btn>
        <v-spacer />
        <v-btn :disabled="build.details.status !== 0" color="error" @click="abortDialog = true"
          ><v-icon class="mr-1">mdi-alert-circle</v-icon> Abortar build</v-btn
        >
        <v-btn color="primary" @click="retryDialog = true"><v-icon class="mr-1">mdi-alert-circle</v-icon> Realizar novo build</v-btn>
      </v-card-actions>

      <!-- abort dialog -->
      <v-dialog v-model="abortDialog" max-width="600" @click:outside="abortDialog = false" @keydown.esc="abortDialog = false">
        <v-form ref="form" v-model="valid" lazy-validation style="background-color: none;">
          <v-card ripple class="pa-6">
            <span>Por quê este build está sendo abortado:</span>
            <v-text-field v-model="abortReason" label="Motivo" outlined :counter="100" :rules="[v => !!v || 'Campo obrigatório']" />
            <v-card-actions class="pt-2 pb-0">
              <v-btn class="mb-2 mr-2" block color="error" @click="abortBuild"><v-icon class="mr-1">mdi-alert-circle</v-icon> Continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- retry dialog -->
      <v-dialog v-model="retryDialog" max-width="600" @click:outside="retryDialog = false" @keydown.esc="retryDialog = false">
        <v-form ref="form" v-model="valid" lazy-validation style="background-color: none;">
          <v-card ripple class="pa-6 mx-auto">
            <h2 align="center" class="mb-4">
              Atenção! O build será re-inserido na fila do Bitrise utilizando os mesmos parâmetros do build atual. Deseja continuar?
            </h2>
            <v-card-actions class="pt-2 pb-0">
              <v-btn class="mb-2 mr-2" block color="primary" @click="retryBuild"><v-icon class="mr-2">mdi-alert-circle</v-icon> Continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- build status -->
      <build-status-message :dialog.sync="statusDialog" :message="message" @close="closeStatus" />
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_ABORT_BUILD, MUTATION_RETRY_BUILD } from '@/modules/app/graphql/index.js'

export default {
  components: {
    BuildStatusMessage: () => import('@/modules/app/views/components/BuildStatusMessage.vue')
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    build: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    abortReason: '',
    abortDialog: false,
    retryDialog: false,
    statusDialog: false,
    valid: false,
    message: ''
  }),
  methods: {
    cancel() {
      this.$emit('update:dialog', false)
      this.$emit('close')
    },
    openStatus() {
      this.message = this.build?.build_status
      this.statusDialog = true
    },
    closeStatus() {
      this.statusDialog = false
      this.message = ''
    },
    cleanAbortDialog() {
      this.abortReason = ''
      this.abortDialog = false
    },
    async abortBuild() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Falha ao validar campos', snackbarColor: '#F44336' })
      const build_slug = this.build?.build_output?.build_slug

      try {
        const res = await this.$apollo.mutate({
          mutation: MUTATION_ABORT_BUILD,
          variables: {
            build_slug,
            abort_reason: this.abortReason,
            abort_with_success: false,
            skip_notifications: false
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_build']
          }
        })

        const message = res?.data?.abortBuild?.message
        this.$snackbar({ message, snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao abortar build', snackbarColor: '#F44336' })
      }
      this.cleanAbortDialog()
      this.cancel()
    },
    async retryBuild() {
      try {
        const res = await this.$apollo.mutate({
          mutation: MUTATION_RETRY_BUILD,
          variables: { build_id: this.build?._id },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_build']
          }
        })

        const message = res?.data?.retryBuild?.message
        this.$snackbar({ message, snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao realizar nova build', snackbarColor: '#F44336' })
      }

      this.retryDialog = false
      this.cancel()
    }
  }
}
</script>
